(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
import { createGlobalStyle } from "styled-components";
import bigCalendarCss from "~/components/GlobalStyle/css/bigCalendar.css";
import swiperCss from "~/components/GlobalStyle/css/swiper.css";
import { lexicalGlobalCss } from "~/components/GlobalStyle/css/lexical.css";
const _default = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }
  body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  html {
    background-color: ${_ref => {
  let {
    theme
  } = _ref;
  return theme.colors.background.primary;
}};
    font-size: ${_ref2 => {
  let {
    theme
  } = _ref2;
  return theme.typography.fontSize;
}}px;
    
    @media (max-width: 1440px) {
      font-size: ${_ref3 => {
  let {
    theme
  } = _ref3;
  return theme.typography.fontSize - 1;
}}px;
    }

    @media (max-width: 1366px) {
      font-size: ${_ref4 => {
  let {
    theme
  } = _ref4;
  return theme.typography.fontSize - 2;
}}px;
    }
  }
  
  body {
    font-family: ${_ref5 => {
  let {
    theme
  } = _ref5;
  return theme.typography.fontFamily.primary;
}};
    font-size: 1rem;
    line-height: ${_ref6 => {
  let {
    theme
  } = _ref6;
  return theme.typography.lineHeight;
}};
    color: ${_ref7 => {
  let {
    theme
  } = _ref7;
  return theme.colors.text.primary;
}};
    background-color: ${_ref8 => {
  let {
    theme
  } = _ref8;
  return theme.colors.background.primary;
}};
    overflow: scroll;
  }
  
  hr {
      margin: 10px 0;
      border-color: ${_ref9 => {
  let {
    theme
  } = _ref9;
  return theme.colors.borders.primary;
}};
  }

  menu, ol, ul {
    list-style: initial;
    margin: 0;
    padding: 0;
  }

  b, strong {
    font-weight: 600;
  }
  
  h1, h2, h3, h4, h5, h6, p, button, blockquote, ul, ol, li, span, input, label, select, textarea, pre, code {
    font-family: ${_ref10 => {
  let {
    theme
  } = _ref10;
  return theme.typography.fontFamily.primary;
}};
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: ${_ref11 => {
  let {
    theme
  } = _ref11;
  return theme.typography.fontFamily.secondary;
}};
  }
  
  h1 {
    font-size: 3em;
    line-height: 1.4em;
    font-weight: ${_ref12 => {
  let {
    theme
  } = _ref12;
  return theme.typography.fontWeight.semiBold;
}};
  }

  h2 {
    font-size: 2.1em;
    line-height: 1.4em;
    font-weight: ${_ref13 => {
  let {
    theme
  } = _ref13;
  return theme.typography.fontWeight.semiBold;
}};
  }

  h3 {
    font-size: 1.5em;
    line-height: 1.4em;
    font-weight: ${_ref14 => {
  let {
    theme
  } = _ref14;
  return theme.typography.fontWeight.medium;
}};
  }

  h4 {
    font-size: 1.4em;
    line-height: 1.4em;
    font-weight: ${_ref15 => {
  let {
    theme
  } = _ref15;
  return theme.typography.fontWeight.medium;
}};
  }

  h5 {
    font-size: 1.2em;
    line-height: 1.4em;
    font-weight: ${_ref16 => {
  let {
    theme
  } = _ref16;
  return theme.typography.fontWeight.medium;
}};
  }
  
  h6 {
    font-size: 1em;
    line-height: 1.4em;
    font-weight: ${_ref17 => {
  let {
    theme
  } = _ref17;
  return theme.typography.fontWeight.semiBold;
}};
  }
  
  pre {
    overflow-x: auto;
    padding-bottom: 1px;
  }
  
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: ${_ref18 => {
  let {
    theme
  } = _ref18;
  return theme.colors.grey.main;
}};
  }
  &::-webkit-scrollbar-thumb {
    background: ${_ref19 => {
  let {
    theme
  } = _ref19;
  return theme.colors.secondary.main;
}};
    opacity: 0.1;
    border-radius: 2em;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
      -webkit-background-clip: text;
      -webkit-text-fill-color: ${_ref20 => {
  let {
    theme
  } = _ref20;
  return theme.colors.secondary.main;
}};
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: transparent;
      caret-color: ${_ref21 => {
  let {
    theme
  } = _ref21;
  return theme.colors.secondary.main;
}};
  }

  .react-tooltip {
    padding: 5px 8px;
    line-height: 1.2em;
    max-width: 200px;
    word-wrap: break-word;
    z-index: 100000;
    background: ${_ref22 => {
  let {
    theme
  } = _ref22;
  return theme.colors.grey.text;
}};
    color: ${_ref23 => {
  let {
    theme
  } = _ref23;
  return theme.colors.grey.main;
}};
  }

  .react-tooltip-button-wrapper {
    display: flex;
  }
  
  ${swiperCss};
  ${bigCalendarCss};
  ${lexicalGlobalCss};
`;
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(_default, "default", "/codebuild/output/src3698642510/src/src/components/GlobalStyle/GlobalStyle.tsx");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();