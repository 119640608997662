(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
import { css } from "styled-components";
const swiperCss = css`
  .swiper {
    width: 100%;
    height: 378px;
  }

  .swiper-slide {
    background: transparent;
  }

  .swiper-pagination {
    right: 20px !important;
  }

  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet {
    background: transparent;
    opacity: 1;
    margin: 9px 0 !important;
    width: 16px;
    height: 16px;
    position: relative;
  }

  .swiper-pagination-bullet-active:after,
  .swiper-pagination-bullet:after {
    content: "";
    background: #ffffff;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }

  .swiper-pagination-bullet-active:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
  }

  .swiper-3d .swiper-slide-shadow {
    background: transparent;
  }
`;
const _default = swiperCss;
export default _default;
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(swiperCss, "swiperCss", "/codebuild/output/src3698642510/src/src/components/GlobalStyle/css/swiper.css.tsx");
  reactHotLoader.register(_default, "default", "/codebuild/output/src3698642510/src/src/components/GlobalStyle/css/swiper.css.tsx");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();